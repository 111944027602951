












































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default defineComponent({
  name: 'Chem1301Lab3Discussion3',
  components: {CalculationInput, STextarea, TableInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        step18Calc: null,
        step18: null,
        step20: null,
        step20Calc: null,
        step20c: null,
        step22: null,
        step22b: null,
        step24: null,
        step24b: null,
        step26: null,
        step28: null,
        step28b: null,
        step31: null,
        step32Calc1: null,
        step32Calc2: null,
        step32: null,
        step34: null,
        step36Calc1: null,
        step36Calc2: null,
        step36: null,
      },
      rows: [
        {
          step: '18 a)',
          input: 'step18Calc',
          calc: true,
          question: 'What is the pH of a 0.1 M solution of HCl?',
        },
        {
          step: '18 b)',
          input: 'step18',
          calc: false,
          question:
            'In this step, you are asked to measure the pH of the acetic acid. Is the pH you get reasonable? Is it consistent with the type of acid you associate with acetic acid?',
        },
        {
          step: '20 a)',
          input: 'step20',
          calc: false,
          question:
            'Do you expect sodium acetate to exhibit acidic or basic behavior when it dissolves in water? If so, which part of the substance contributes to this behavior?',
        },
        {
          step: '20 b)',
          input: 'step20Calc',
          calc: true,
          question: 'What is the pH of a 0.1 M solution of NaOH?',
        },
        {
          step: '20 c)',
          input: 'step20c',
          calc: false,
          question:
            'In this step, you are asked to measure the pH of the sodium acetate. Is the pH you get reasonable? Is it consistent with the type of substance you associate with sodium acetate?',
        },
        {
          step: '22 a)',
          input: 'step22',
          calc: false,
          question:
            'In this step, you are asked to measure the pH when you mix the solutions of acetic acid and sodium acetate. Is the pH you get what you might have expected?',
        },
        {
          step: '22 b)',
          input: 'step22b',
          calc: false,
          question:
            'Given that the $\\text{K}_\\text{a}$ of acetic acid is $1.8\\times10^{-5},$ try to calculate the pH of the buffer using the initial concentrations of the acid and the salt. How does this value compare to the value you measured? [Optional]',
        },
        {
          step: '24 a)',
          input: 'step24',
          calc: false,
          question:
            'In this step, you have added a small amount of strong acid to the buffer. What do you think the effect of this addition will be on the pH of the buffer? Is what you thought consistent with what you observed experimentally?',
        },
        {
          step: '24 b)',
          input: 'step24b',
          calc: false,
          question:
            'Try to calculate the pH of the buffer after the strong acid has been added. In your calculation, you may take 20 drops to equal 1 mL. Does the value you calculate agree with the value you measured? What does this tell you? [Optional]',
        },
        {
          step: '26',
          input: 'step26',
          calc: false,
          question:
            'In the previous step, you added 20 drops (~ 1 mL) of 0.1 mol/L HCl to the buffer. In this step, you added 20 drops (~ 1 mL) of 0.1 mol/L NaOH to the buffer. What do you expect the effect of the addition of this strong base to be on the pH of the buffer? Why?',
        },
        {
          step: '28 a)',
          input: 'step28',
          calc: false,
          question:
            'In this step, you have added an additional small amount of strong base to the buffer. What do you think the effect of this addition will be on the pH of the buffer? Is what you thought consistent with what you observed experimentally?',
        },
        {
          step: '28 b)',
          input: 'step28b',
          calc: false,
          question:
            'Try to calculate the pH of the buffer after the strong base has been added. In your calculation, you may take 20 drops to equal 1 mL. Does the value you calculate agree with the value you measured? What does this tell you?',
        },
        {
          step: '31',
          input: 'step31',
          calc: false,
          question:
            'What did you observe about the pH of distilled water? Is it what you expected the pH of water to be? Can you explain your observed pH?',
        },
        {
          step: '32 a)',
          input: 'step32Calc1',
          calc: true,
          question:
            'Calculate the concentration of the strong acid diluting it (assuming you added exactly 1 mL of the acid to 20 mL of water)?',
        },
        {
          step: '32 b)',
          input: 'step32Calc2',
          calc: true,
          question: 'Calculate the expected pH of the diluted acid solution.',
        },
        {
          step: '32 c)',
          input: 'step32',
          calc: false,
          question:
            'How does this pH value compare to the pH value you measured in this step? How does this value compare to the pH of the buffer solution (Step 24) after the same amount of strong acid was added? What does this demonstrate to you?',
        },
        {
          step: '34',
          input: 'step34',
          calc: false,
          question:
            'In the previous step, you added 20 drops (~ 1 mL) of 0.1 mol/L HCl to distilled water. In this step, you added 20 drops (~ 1 mL) of 0.1 mol/L NaOH to the acid solution. What do you expect the effect of the addition of this strong base to be on the pH? Why?',
        },
        {
          step: '36 a)',
          input: 'step36Calc1',
          calc: true,
          question:
            'Calculate the concentration of the strong base diluting it (assuming you added exactly 1 mL of the base to the 20 mL of water that already had 1 mL of HCl and 1 mL of NaOH added to it, i.e. adding 1 mL of base to a 22 mL solution of essentially water)?',
        },
        {
          step: '36 b)',
          input: 'step36Calc2',
          calc: true,
          question: 'Calculate the expected pH of the diluted base solution.',
        },
        {
          step: '36 c)',
          input: 'step36',
          calc: false,
          question:
            'How does this pH value compare to the pH value you measured in this step? How does this value compare to the pH of the buffer solution (Step 28) after the same amount of strong base was added? What does this demonstrate to you?',
        },
      ],
    };
  },
});
